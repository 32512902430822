<template>
  <div class="layout" :style="bk ? `background:rgb(${bk})` : ''">
    <van-nav-bar
      @click-left="$router.back()"
      left-arrow
      title="专区详情"
      z-index="999"
      :fixed="true"
      :border="false"
      :style="bk ? `background:rgb(${bk});color:#fff` : ''"
    />
    <img :src="obj.platformPic" crossorigin="anonymous" id="bannerImg" />

    <van-loading v-if="loading" />
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      success-text="刷新成功"
      v-else-if="goods.length"
    >
      <div class="goods_list" id="goods_list">
        <goods
          v-for="(item, index) in goods"
          :key="index"
          :title="item.commodityName"
          :goodsInfo="item"
        />
      </div>
    </van-pull-refresh>
    <van-empty description="暂无商品" v-else />
    <functionButton  :homePageBtn="true"  />
  </div>
</template>

<script>
import Goods from "@/components/Goods";
import { WechatSharing } from "@/utils/sdk";
import { mapState } from "vuex";
import ColorThief from "colorthief";

export default {
  name: "activity-detail",
  components: { Goods },

  data() {
    return {
      obj: {},
      goods: [],
      loading: true,
      refreshing: false,
      bk: "",
    };
  },
  computed: {
    ...mapState("user", ["tzInfo", "userInfo", "isLogin"]),
  },
  mounted() {},
  created() {
    if (this.$route.query.id) {
      // if (this.$route.query.id && !sessionStorage.getItem("userUuid")) {
      sessionStorage.setItem("userUuid", this.$route.query.id);
      // }
    } else {
      this.$router.replace({
        path: "activityDetail",
        query: {
          activityId: this.$route.query.activityId,
          id: sessionStorage.getItem("userUuid")
            ? sessionStorage.getItem("userUuid")
            : undefined,
        },
      });
    }

    // this.getInit();
    // this.getInfo();
  },
  activated() {
    // this.getInit();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "goods-details") {
      next((vm) => {
        vm.loading = true;
        vm.refreshing = false;
        vm.obj = {};
        vm.goods = [];
        vm.getInit();
        vm.getInfo();
        vm.bk = "";
      });
    }

    next();
  },

  methods: {
    async getInit() {
      const data = await this.$API.homePage.homeActivity({
        object: {
          freeShipping: this.tzInfo ? this.tzInfo.userEntity.freeShipping : "",
          id: this.$route.query.activityId,
        },
        pageNum: 0,
        pageSize: 0,
      });
      this.obj = data.data.data;
      this.obj.platformPic = data.data.data.platformPic.split(",")[1];

      let shareInfo = {
        title: this.obj.activityName,
        desc: this.obj.activityDescribe,
        imgUrl: this.obj.platformPic,
        link: window.location.hash,
      };
      WechatSharing(shareInfo);

      // let colorthief = new ColorThief();
      let img = document.getElementById("bannerImg");
      img.addEventListener("load", () => {
        this.bk = String(new ColorThief().getColor(img));
      });
      // res.forEach(item => {
      //   item.platformPic = item.platformPic.split(',')[1]
      // })
      // this.list = res
    },
    async getInfo() {
      await this.$API.homePage
        .homeActivityComm({
          object: {
            freeShipping: this.tzInfo
              ? this.tzInfo.userEntity.freeShipping
              : "",
            id: this.$route.query.activityId,
            userId: this.isLogin ? this.userInfo.id : undefined,
          },
          pageNum: 0,
          pageSize: 0,
        })
        .then((res) => {
          if (res.data.code === "UNKNOWN_ERROR") throw Error(res.data.msg);
          this.loading = false;
          this.refreshing = false;
          if (!res.data.data) return;
          let Goods = res.data.data.commodityDetailsList.map((item, index) => {
            return {
              ...item,
              ...res.data.data.commodityPriceDetailsList[index],
              ...res.data.data.commodityActivityRspList[index],
            };
          });
          Goods = Goods.filter((item) => item.storeId !== null);
          Goods.forEach((item) => {
            var [id, commId] = [item.commId, item.id];
            item.id = id;
            item.commId = commId;
            item.filePath = item.filePath.split(",")[0].endsWith(".mp4")
              ? item.filePath.split(",")[1]
              : item.filePath.split(",")[0];
            if (
              item.orderCartListItems.length &&
              !item.orderCartListItems[0].isDelete
            ) {
              item.quantity = item.orderCartListItems[0].quantity;
            } else {
              item.quantity = 0;
            }
            item.newUser = res.data.data.newUser;
          });
          this.goods = Goods;

          this.$nextTick(function () {
            this.waterFall();
          });
        })
        .catch((err) => {
          this.Toast.fail(err.message);
          console.error(err.message);
          this.loading = false;
        });
    },
    onRefresh() {
      this.refreshing = true;
      this.getInfo();
    },
    waterFall() {
      // 1. 设置container盒子的宽度
      //      注意：浏览器的可视区域的宽度 / 一个item元素的宽度 = 一行的排列的元素的个数
      let container = document.getElementById("goods_list");
      let item = document.getElementsByClassName("goods_item_box");
      if (!item.length) {
        container.style.height = "auto";
        return;
      }
      //获取元素的宽度(含border，padding)
      let width = item[0].offsetWidth;
      //计算出浏览器窗口的宽度
      let clientWidth = document.documentElement.clientWidth;
      //计算出应该放几列（向下取整）
      // let columnCount = Math.floor(clientWidth / width);
      let columnCount = 2;
      //设置容器（父盒子）的宽度
      // container.style.width = columnCount * width + "px";
      let left = container.offsetWidth - width * columnCount; //宽度间隔
      let height = 12; //高度间隔

      // 2.设置每一个item元素的排列位置
      //  第一行整体的top值都是0 后面的依次找上一行高度最小的容器，在它下面进行排列
      let hrr = [];
      for (let i = 0; i < item.length; i++) {
        //定位第一行的图片
        if (i < columnCount) {
          item[i].style.top = "0px";
          if (i) {
            item[i].style.left = i * width + left + "px";
          } else {
            item[i].style.left = i * width + "px";
          }
          hrr.push(item[i].offsetHeight + height);
        } else {
          //第一行之后的
          //选择总高度最小的列
          let min = Math.min(...hrr);
          let index = hrr.indexOf(min);
          //将每个元素定位到当前总高度最小的列下
          //index为0在左边 为1在右边 2以此类推
          item[i].style.top = min + "px";
          if (index) {
            item[i].style.left = index * width + left + "px";
          } else {
            item[i].style.left = index * width + "px";
          }
          //当前定位的元素加入该列
          hrr[index] += item[i].offsetHeight + height;
        }
      }
      if (hrr.length < 2) {
        container.style.height = `${hrr[0]}px`;
        return;
      }
      if (hrr[0] >= hrr[1]) {
        container.style.height = `${hrr[0]}px`;
      } else {
        container.style.height = `${hrr[1]}px`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  // position: absolute;
  background: #f5f5f5;
  // bottom: 0;
  // top: 46px;
  // left: 0;
  // right: 0;
  // max-width: 500px;
  padding-top: 46px;
  min-height: 100%;
  margin: 0 auto;
  text-align: center;
  .van-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  img {
    width: 100%;
    display: block;
  }
  p {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    color: #333;
    font-size: 14px;
  }
  .goods_list {
    margin: 12px 15px;
    position: relative;
    flex-shrink: 0;
    .goods {
      margin-bottom: 12px;
    }
  }
  ::v-deep .van-pull-refresh {
    flex-shrink: 0;
    min-height: 70vh;
    // flex: auto;
  }
}
</style>
